import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { VotingComponent } from './voting.component';

@NgModule({
  imports: [ FormsModule, CommonModule ],
  declarations: [ VotingComponent ],
  exports: [ VotingComponent ]
})
export class VotingModule {}
