import { Pitch } from './pitch.model';
export class Vote {
  public $key: string;
  public pitch: Pitch;
  public count = 0;

  public unique = 0;

  public total = 0;
}
