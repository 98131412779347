import { VoteContainer } from './../models/votecontainer.model';
import * as firebase from 'firebase';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

import { Pitch } from '../models';
import { map } from 'rxjs/operators';

@Injectable()
export class DatabaseService {

  constructor(public db: AngularFireDatabase) {
    // const firebaseDb = db.database;
    // const firebaseApp = db.app;
  }

  public object(path: string) {
    return this.db.object('/' + path);
  }

  public list(path: string, options?: any) {
    return this.db.list('/' + path, options).snapshotChanges()
  }

  public getLastEntry(table: string) {
    //return of(this.db.list('/' + table, ref => ref.orderByChild('priority').limitToLast(1)))
    // return this.db.list('/' + table, {
    //   query: {
    //     orderByChild: 'priority',
    //     limitToLast: 1
    //   }
    // })
      return this.db.list('/' + table, ref => ref.orderByChild('priority').limitToLast(1)).valueChanges()
      .pipe(map(dataList => {
        if (dataList != null && dataList.length > 0) {
          return dataList[0];
        }
        return null;
      }));
  }

  public getFirstEntry(table: string) {
    return this.db.list('/' + table, ref => ref.orderByChild('priority').limitToFirst(1)).valueChanges()
    .pipe(map(dataList => {
      if (dataList != null && dataList.length > 0) {
        return dataList[0];
      }
      return null;
    }));

    // return of(this.db.list('/' + table, ref => ref.orderByChild('priority').limitToFirst(1)))
    // // return this.db.list('/' + table, {
    // //   query: {
    // //     orderByChild: 'priority',
    // //     limitToFirst: 1
    // //   }
    // // })
    //   .map((dataList) => {
    //     if (dataList != null && dataList.length > 0) {
    //       return dataList[0];
    //     }
    //     return null;
    //   });
  }

  /**
   * Creates a new Pitch
   */
  public createPitch(pitch: Pitch) {
    pitch.timestamp = firebase.database.ServerValue.TIMESTAMP;
    pitch.priority = 0 - Date.now();

    const pitchs = this.db.list('/pitchs_oct_2018');

    return pitchs.push(pitch);
  }


  public findVote(email: string) {
    return this.db.list('/votes_oct_2018', ref => ref.orderByChild('email').equalTo(email)).valueChanges()
      // return this.db.list('/votes', {
      //   query: {
      //     orderByChild: 'email',
      //     equalTo: email
      //   }
      // })
  }

  public createVote(vote: VoteContainer) {

    // TODO - NEED TO CHECK IF NOT VOTED AGAIN?
    vote.timestamp = firebase.database.ServerValue.TIMESTAMP;
    vote.priority = 0 - Date.now();

    const votes = this.db.list('/votes_oct_2018');

    
    // vote.timestamp = firebase.database.ServerValue.TIMESTAMP;
    // vote.priority = 0 - Date.now();
    // const votes = this.db.list('/votes');
    return votes.push(vote);
  }

//   public createUser(user: User) {
//     user.createdAt = firebase.database.ServerValue.TIMESTAMP;
//     user.updatedAt = firebase.database.ServerValue.TIMESTAMP;
//     user.priority = 0 - Date.now();

//     const users = this.db.list('/users');
//     return users.push(user);
//   }
}
