import { environment } from './../../../environments/environment';
import { Pitch } from './../../shared/models/pitch.model';
import { VoteContainer } from './../../shared/models/votecontainer.model';
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from './../../shared/services/database.service';
import { NotificationsService } from 'angular2-notifications';
import { Location } from '@angular/common';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Vote } from './../../shared/models/vote.model';
import { filter, map, reduce } from 'rxjs/operators';

@Component({
    selector: 'app-results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.css']
})
export class ResultsComponent implements OnInit {

    showLoading = true;
    voteContainers: VoteContainer[];

    // votes = [];
    votes: { [key: string]: Vote; } = { };
    results: Vote[] = []

    totalVoted = 0
    totalVotes = 0

    constructor(private database: DatabaseService,
        private location: Location,
        private router: Router,
        private notificationsService: NotificationsService,
        private modalService: NgbModal) {
    }

    ngOnInit() {
        // // Not in production mode
        // if (environment.production) {
        //     return
        // }

        this.database.list('votes_oct_2018')
            .pipe(
                map(changes =>
                    changes.map(c => {
                        const data = c.payload.val() as VoteContainer;
                        const id = c.payload.key;
                        return { id, ...data };
                    })
                )
            )
            .subscribe((results) => {
                this.voteContainers = results
                this.totalVoted = this.voteContainers.length

                this.processVotes(this.voteContainers)

                this.buildResults()

                this.showLoading = false
            }, (error) => {
                this.notificationsService.error(
                    'Oops',
                    'Vote results could not be loaded',
                    {
                        timeOut: 5000,
                        showProgressBar: true,
                        pauseOnHover: false,
                        clickToClose: true,
                        position: ['top', 'right'],
                    }
                );
            })
    }

    processVotes(votes: VoteContainer[]): void {
        for (const pp of votes) {
            for (const p of pp.votes) {
                const key = p.key
                const vote = p.value as Vote
                // console.log(vote)

                // const pitch = vote.pitch as Pitch

                const cVote = this.votes[key]
                if (cVote === undefined) {
                    vote.unique = 1
                    this.votes[key] = vote
                } else {
                    // console.log(cVote)

                    cVote.unique = cVote.unique + 1

                    cVote.count = cVote.count + vote.count
                    this.votes[key] = cVote
                }
            }
        }
    }

    buildResults() {
        const keys = Object.keys(this.votes);
        for (const key of keys) {
            const vote = this.votes[key] as Vote

            // DVG - TESTING
            vote.total = vote.unique + vote.count
            // vote.total = vote.count

            this.totalVotes += vote.count
            this.results.push(vote)
        }
        // Sort it - DESC
        this.results = this.results.sort((v1, v2) => {
                if (v1.total > v2.total) {
                    return -1;
                }

                if (v1.total === v2.total) {
                    return 0
                }

                if (v1.total < v2.total) {
                    return 1;
                }

                return 0;
            });
    }

}
