import { VoteContainer } from './../../shared/models/votecontainer.model';
import { Pitch } from './../../shared/models';
import { Component, OnInit } from '@angular/core';
import { DatabaseService } from './../../shared/services/database.service';
import { NotificationsService } from 'angular2-notifications';
import { Location } from '@angular/common';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
    selector: 'app-vote',
    templateUrl: './vote.component.html',
    styleUrls: ['./vote.component.css']
})
export class VoteComponent implements OnInit {

    pitches: Pitch[];
    showLoading = true;
    voteContainer = new VoteContainer();

    modal: NgbModalRef = null

    constructor(private database: DatabaseService,
        private location: Location,
        private router: Router,
        private notificationsService: NotificationsService,
        private modalService: NgbModal) {
    }

    ngOnInit() {
    }

    dataChanged(event: any): void {
        this.pitches = event.data;
        this.showLoading = false;
        console.log('Pitches loaed')
    }

    loadingChanged(show: boolean): void {
        this.showLoading = show;
    }

    onSelectListItem(ptich: Pitch) {

    }

    votingChanged(voteContainer: VoteContainer): void {
        this.voteContainer = voteContainer;
    }

    votingLimit(): void {
        // SHOW ALERT
        this.notificationsService.error(
            'Vote Limit Reached',
            'Only 3 votes allowed',
            {
                timeOut: 3000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true,
                position: ['top', 'right'],
            }
        );
    }

    castVote(): void {
        if (this.modal != null) {
            this.modal.close();
        }

        // Check if not already voted?
        const emailSubscription = this.database.findVote(this.voteContainer.email)
            .subscribe((res) => {
                if (res == null || res.length === 0) {
                    this.doVote()
                } else {
                    this.notificationsService.error(
                        'Ooops!',
                        'You can only vote ONCE!!',
                        {
                            timeOut: 5000,
                            showProgressBar: true,
                            pauseOnHover: false,
                            clickToClose: true,
                            position: ['top', 'right'],
                        }
                    );
                }

                emailSubscription.unsubscribe();
            }, (error) => {
                console.log(error);
                emailSubscription.unsubscribe();
            });

    }

    open(content) {
        this.modal = this.modalService.open(content);
    }

    private doVote() {
        this.database.createVote(this.voteContainer)
            .then(onsuccess => {
                // All Good
                this.notificationsService.success(
                    'Thanks',
                    'Votes has been recorded.',
                    {
                        timeOut: 5000,
                        showProgressBar: true,
                        pauseOnHover: false,
                        clickToClose: true,
                        position: ['top', 'right'],
                    }
                );

                this.router.navigate(['/home']);
            }, onfailure => {
                // Show error message
                console.log(onfailure);

                this.notificationsService.error(
                    'Ooops!',
                    'Please vote again! Error occurred!',
                    {
                        timeOut: 5000,
                        showProgressBar: true,
                        pauseOnHover: false,
                        clickToClose: true,
                        position: ['top', 'right'],
                    }
                );
            });
            // .then((res) => {
            //     if (res == null) {
            //         // Show error message
            //         console.log(res);

            //         this.notificationsService.error(
            //             'Ooops!',
            //             'Please vote again! Error occurred!',
            //             {
            //                 timeOut: 5000,
            //                 showProgressBar: true,
            //                 pauseOnHover: false,
            //                 clickToClose: true,
            //                 position: ['top', 'right'],
            //             }
            //         );

            //     } else {
            //         // All Good
            //         this.notificationsService.success(
            //             'Thanks',
            //             'Votes has been recorded.',
            //             {
            //                 timeOut: 5000,
            //                 showProgressBar: true,
            //                 pauseOnHover: false,
            //                 clickToClose: true,
            //                 position: ['top', 'right'],
            //             }
            //         );

            //         this.router.navigate(['/home']);
            //     }

            // })
            // .catch(err => {
            //     // console.log(err, 'You do not have access!')
            //     this.notificationsService.error(
            //         'Invalid Email',
            //         'Check your email address and try again.',
            //         {
            //             timeOut: 5000,
            //             showProgressBar: true,
            //             pauseOnHover: false,
            //             clickToClose: true,
            //             position: ['top', 'right'],
            //         }
            //     );
            // });
    }
}
