import { DatabaseService } from './../../shared/services/database.service';
import { Pitch } from './../../shared/models';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { NotificationsService } from 'angular2-notifications';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pitch-submit',
  templateUrl: './pitch-submit.component.html',
  styleUrls: ['./pitch-submit.component.css']
})
export class PitchSubmitComponent implements OnInit {

    model = new Pitch();

    constructor(private database: DatabaseService,
        private location: Location,
        private router: Router,
        private notificationsService: NotificationsService) {
    }

    ngOnInit() {
    }

    onSubmit(): void {
        // Not in production mode
        if (environment.production) {
            return
        }
        console.log(JSON.stringify(this.model));

        // createPitch
        // Create the Machine entry in the database
        this.database.createPitch(this.model)
            .then((res) => {
            if (res == null) {
                // Show error message
                console.log(res);
            } else {
                // All Good
                // this.onBack();

                this.notificationsService.success(
                    'Pitch Created',
                    this.model.author + ' good luck!.',
                    {
                        timeOut: 5000,
                        showProgressBar: true,
                        pauseOnHover: false,
                        clickToClose: true,
                        position: ['top', 'right'],
                    }
                );

                this.router.navigate(['/home']);
                this.model = new Pitch();
        }
        });
    }

    onBack(): void {
        this.location.back();
    }

}
