import { Pitch } from '../../shared/models';
import { Component, OnInit } from '@angular/core';
import {forEach} from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {

    pitches: Pitch[];
    showLoading = true;

    constructor() {
    }

    ngOnInit() {
    }

    dataChanged(event: any): void {
      this.pitches = [];
      for (const entry of event.data) {
        if (entry.members != null && entry.members.length > 0) {
          this.pitches.push(entry);
        }
      }

        this.showLoading = false;
        // console.log('Pitches loaed');
    }

    loadingChanged(show: boolean): void {
        this.showLoading = show;
    }

    onSelectListItem(ptich: Pitch) {

    }

}
