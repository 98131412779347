import { NgModule } from '@angular/core';

import { LottieAnimationViewModule } from 'lottie-angular2';
import { LoadingComponent } from './loading.component';

@NgModule({
  imports: [ LottieAnimationViewModule.forRoot() ],
  declarations: [ LoadingComponent ],
  exports: [ LoadingComponent ]
})
export class LoadingModule {}

// @NgModule({
//   imports: [ LoadingModule, LottieAnimationViewModule.forRoot(), ]
// })
// class SharedComponent { }
