import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DatabaseService } from '../../../shared/services';
import { filter, mergeMap, map } from 'rxjs/operators';

export interface DataEvent {
  data: any[];
}

@Component({
  selector: 'app-pager',
  template: `
    <ul class="pager" style="padding: 0">
      <li hidden="noPrevious()" [class.disabled]="noPrevious()" [class.previous]="align"
          [ngClass]="{'pull-right': align}" class="btn btn-link">
        <a href (click)="prevPage($event)">« Previous</a>
      </li>
      <li hidden="noNext()" [class.disabled]="noNext()" [class.next]="align" [ngClass]="{'pull-right': align}" class="btn btn-link">
        <a href (click)="nextPage($event)">Next »</a>
      </li>
  </ul>
`
})
export class PagerComponent implements OnInit {
  /** firebase table */
  @Input() public tableName: string;
  /** items per page */
  @Input() public itemsPerPage: number;

  /** maximum number of items per page. If value less than 1 will display all items on one page */
//  protected _itemsPerPage: number;

  /** fired when total pages count changes, $event:number equals to total pages count */
  @Output() public loadingChanged: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  /** fired when data was changed, $event:{ data[] } */
  @Output() public dataChanged: EventEmitter<DataEvent> = new EventEmitter<DataEvent>();

  private data: any[];

  private firstEntry: any;
  private lastEntry: any;
  private prevValue: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private nextValue: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private startAtValue: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private endAtValue: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  queryableNext = false;
  queryablePrev = false;

  constructor(private db: DatabaseService) {
  }

  ngOnInit() {

    // asyncronously find the first and last item in the list
    this.db.getFirstEntry(this.tableName)
    .pipe(
      mergeMap((entry) => {
        this.firstEntry = entry;
        // Set the start point
        if (this.firstEntry != null) {
          this.startAtValue.next(this.firstEntry.priority);
        }

        return this.db.getLastEntry(this.tableName);
      }))
      .pipe(
        map((entry) => {
          this.lastEntry = entry;
          return true;
        })
      )
      .subscribe(() => {

        // Next
        this.startAt().subscribe((result) => {
          this.onResult(result);
        });
        // Prev
        this.endAt().subscribe((result) => {
          this.onResult(result);
        });

      })
  }

  private startAt() {
    return this.db.list(this.tableName, ref => ref.orderByChild('priority').limitToFirst(this.itemsPerPage + 1).startAt(this.startAtValue.getValue()))
    // return this.db.list(this.tableName, {
    //       query: {
    //           orderByChild: 'priority',
    //           limitToFirst: (this.itemsPerPage + 1),
    //           startAt: this.startAtValue
    //       }
    //     })
      .pipe(
        map(changes =>
            changes.map(c => {
                const data = c.payload.val() as any;
                const id = c.payload.key;
                return { id, ...data };
            })
        )
      )
      .pipe(filter((ref) => this.startAtValue.getValue() !== 0));
  }

  private endAt() {
    return this.db.list(this.tableName, ref => ref.orderByChild('priority').limitToLast(this.itemsPerPage + 1).endAt(this.endAtValue.getValue()))
    .pipe(
      map(changes =>
          changes.map(c => {
              const data = c.payload.val() as any;
              const id = c.payload.key;
              return { id, ...data };
          })
      )
  )
    // return this.db.list(this.tableName, {
    //       query: {
    //           orderByChild: 'priority',
    //           limitToLast: (this.itemsPerPage + 1),
    //           endAt: this.endAtValue
    //       }
    //     })
        .pipe(filter((ref) => this.endAtValue.getValue() !== 0));
  }

  private onResult(result: any[]) {
      if (result != null && result.length > 0) {
        let count = result.length;

        // Query End at first item
        this.prevValue.next(result[0].priority);
        // Query Start at the last item (Spare)
        this.nextValue.next(result[count - 1].priority);

        // Check if we have an spare and remove it
        if (count === (this.itemsPerPage + 1)) {
          result = result.slice(0, count - 1);
          count = result.length;
        }
        this.data = result;

        // Next || Prev Options
        this.queryablePrev = !(this.firstEntry.$key === this.data[0].$key);
        this.queryableNext = !(this.lastEntry.$key === this.data[count - 1].$key);
      } else {
        this.queryableNext = false;
        this.queryablePrev = false;
        this.data = null;
      }

      this.dataChanged.emit({
        data: this.data
      });

      this.loadingChanged.emit(false);
  }

  public noPrevious(): boolean {
    return !this.queryablePrev;
  }

  public noNext(): boolean {
    return !this.queryableNext;
  }

  public nextPage(event?: Event): void {
    if (event) {
      event.preventDefault();
    }

    if (this.queryableNext) {
      if (event && event.target) {
        const target: any = event.target;
        target.blur();
      }
      this.loadingChanged.emit(true);
      this.startAtValue.next(this.nextValue.getValue());
    }
  }

  public prevPage(event?: Event): void {
    if (event) {
      event.preventDefault();
    }

    if (this.queryablePrev) {
      if (event && event.target) {
        const target: any = event.target;
        target.blur();
      }
      this.loadingChanged.emit(true);
      this.endAtValue.next(this.prevValue.getValue());
    }
  }
}
