import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { interval } from 'rxjs';
import { filter, map, reduce } from 'rxjs/operators';

@Component({
  selector: 'app-home-countdown',
  templateUrl: './home-countdown.component.html',
  styleUrls: ['./home-countdown.component.css'],
  providers: [NgbCarouselConfig]
})
export class HomeCountdownComponent implements OnInit, OnDestroy {

  private future: Date;
  private futureString = 'October 25, 2018 09:00:00';
  private diff: number;
  private $counter: Observable<number>;
  private subscription: Subscription;

  public days = 0;
  public hours = 0;
  public minutes = 0;
  public seconds = 0;

  constructor(config: NgbCarouselConfig) {
    // customize default values of carousels used by this component tree
    config.interval = 5000;
    config.wrap = true;
    config.keyboard = false;
  }

  dhms(diff) {
    // Check past due date
    if (diff <= 0) {
      this.days = this.pad(0);
      this.hours = this.pad(0);
      this.minutes = this.pad(0);
      this.seconds = this.pad(0);
    } else {
      this.days = this.pad(Math.floor(diff / 86400));
      diff -= this.days * 86400;
      this.hours = this.pad(Math.floor(diff / 3600) % 24);
      diff -= this.hours * 3600;
      this.minutes = this.pad(Math.floor(diff / 60) % 60);
      diff -= this.minutes * 60;
      this.seconds = this.pad(diff % 60);
    }
  }

  pad(n) {
    return (n < 10) ? ('0' + n) : n;
  }

  ngOnInit() {
    this.future = new Date(this.futureString);
    this.$counter = interval(1000).pipe(map((x) => {
      this.diff = Math.floor((this.future.getTime() - new Date().getTime()) / 1000);
      return x;
    }));

    this.subscription = this.$counter
      .subscribe((x) => {
        this.dhms(this.diff);
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
