import { ResultsComponent } from './components/results/results.component';
import { PitchesComponent } from './components/pitches/pitches.component';
import { VoteComponent } from './components/vote/vote.component';
import { PitchSubmitComponent } from './components/pitch-submit/pitch-submit.component';
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HomeCountdownComponent } from './components/home-countdown/home-countdown.component';
import {ProjectsComponent} from './components/projects/projects.component';

const appRoutes: Routes = [
    { path: '',                         component: HomeCountdownComponent },
    { path: 'home',                     component: HomeCountdownComponent },
    // { path: 'pitch-submit',             component: PitchSubmitComponent },
    // { path: 'vote',                     component: VoteComponent },
    { path: 'pitches',                  component: PitchesComponent },
    // { path: 'results',                  component: ResultsComponent },
    { path: 'projects',                  component: ProjectsComponent },
    { path: '**',                       component: PageNotFoundComponent }
];

export const Routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
