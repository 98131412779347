import { Pitch } from './../../shared/models';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pitches',
  templateUrl: './pitches.component.html',
  styleUrls: ['./pitches.component.css']
})
export class PitchesComponent implements OnInit {

    pitches: Pitch[];
    showLoading = true;

    constructor() {
    }

    ngOnInit() {
    }

    dataChanged(event: any): void {
        this.pitches = event.data;
        this.showLoading = false;
        console.log('Pitches loaed')
    }

    loadingChanged(show: boolean): void {
        this.showLoading = show;
    }

    onSelectListItem(ptich: Pitch) {

    }

}
