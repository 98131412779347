import { VoteContainer } from './../../models/votecontainer.model';
import { Pitch, Vote } from './../../models';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

/**
 * <voting [voteCount]="post.voteCount" [myVote]="post.myVote" (vote)="onVote($event)"></voting>
 */
@Component({
    selector: 'app-voting',
    templateUrl: './voting.component.html',
    // template: `
    //     <div class="voter">
    //         <li
    //             class="glyphicon glyphicon-menu-up vote-button"
    //             [class.highlighted]="votes == 1"
    //             (click)="upVote()"
    //         ></li>
    //         <span class="vote-count">{{ count + votes }}</span>
    //         <li
    //             class="glyphicon glyphicon-menu-down vote-button"
    //             [class.highlighted]="votes == -1"
    //             (click)="downVote()"
    //         ></li>
    //     </div>
    // `,
    styles: [`
        .icon {
            display: inline-block;
            cursor: pointer;
        }
        .voter {
            width: 20px;
            font-size: 34px;
            text-align: center;
            color: #999;
        }
        .vote-button {
            cursor: pointer;
        }
        .highlighted {
            color: gold;
            background-color: red;
        }
    `]
})
export class VotingComponent {
    private robotYes = '/assets/images/Robot-icon-gold.png';
    // private robotNo = '/assets/images/Robot-icon-grey.png';
    private robotNo = '/assets/images/Robot-icon-str.png';

    vote1 = false
    vote2 = false
    vote3 = false

    robot1 = this.robotNo;
    robot2 = this.robotNo;
    robot3 = this.robotNo;

    max = 3

    @Input() pitch: Pitch;
    @Input() voteContainer: VoteContainer;

    @Output() votingChanged = new EventEmitter<VoteContainer>();
    @Output() votingLimit = new EventEmitter<Boolean>();

    private prevVoteId: Number = -1

    vote(id: Number) {

        let voted = false
        if (this.prevVoteId !== id) {
            voted = true
        } else {
            if (id === 1) {
                voted = !this.vote1;
            } else if (id === 2) {
                voted = !this.vote2;
            } else {
                voted = !this.vote3;
            }
        }
        this.prevVoteId = id

        // let preVote1 = false, preVote2 = false, preVote3 = false
        // if (id === 1) {
        //     preVote1 = voted;
        // } else if (id === 2) {
        //     preVote1 = voted;
        //     preVote2 = voted;
        // } else {
        //     preVote1 = voted;
        //     preVote2 = voted;
        //     preVote3 = voted;
        // }

        // Add the pitch to the list
        // for (const p of this.voteModel.pitches) {
        //     if (p.$key === this.pitch.$key) {
        //         if (voted) {
        //             // Already in the list
        //         } else {
        //             // Remove
        //             this.voteModel.pitches.remove
        //         }
        //     }
        // }

        let count = 0
        if (id === 1) {
            count = 1
        } else if (id === 2) {
            count = 2
        } else {
            count = 3
        }

        // Check if we can have more votes
        let curCount = count
        for (const pp of this.voteContainer.votes) {
            if (pp.key === this.pitch.$key) {
                // Ignore the current one
            } else {
                curCount = curCount + pp.value.count
            }
        }

        if (curCount > this.max) {
            // MAYBE
            count--;
            if (count > 0) {
                this.vote(count)
            } else {
                this.votingLimit.emit();
            }
            return
        }

        let found = false;
        let idx = 0;
        for (const pp of this.voteContainer.votes) {
            if (pp.key === this.pitch.$key) {
                found = true
                break
            }

            idx++;
        }

        if (found) {
            this.voteContainer.votes.splice(idx, 1);
        }

        if (voted) {
            const v = new Vote();
            v.count = count
            v.pitch = this.pitch
            this.voteContainer.votes.push({key: this.pitch.$key, value: v});
        }

        this.updateDisplay(id, voted);
        this.emitEvent();
    }

    // voteFAIL(id: Number) {
    //     let preVote1 = false, preVote2 = false, preVote3 = false
    //     let voted = false

    //     if (this.prevVoteId !== id) {
    //         voted = true
    //     } else {
    //         if (id === 1) {
    //             voted = !this.vote1;
    //         } else if (id === 2) {
    //             voted = !this.vote2;
    //         } else {
    //             voted = !this.vote3;
    //         }
    //     }
    //     this.prevVoteId = id

    //     if (id === 1) {
    //         preVote1 = voted;
    //     } else if (id === 2) {
    //         preVote1 = voted;
    //         preVote2 = voted;
    //     } else {
    //         preVote1 = voted;
    //         preVote2 = voted;
    //         preVote3 = voted;
    //     }


    //     let curVotes = 0

    //     if (this.voteModel.vote1 === '') {
    //     } else {
    //         // Check if same item - don't count
    //         if (this.voteModel.vote1 === this.pitch.$key) {
    //             // NO NO
    //         } else {
    //             curVotes++
    //         }
    //     }
    //     if (this.voteModel.vote2 === '') {
    //     } else {
    //         // Check if same item - don't count
    //         if (this.voteModel.vote2 === this.pitch.$key) {
    //             // NO NO
    //         } else {
    //             curVotes++
    //         }
    //     }
    //     if (this.voteModel.vote3 === '') {
    //     } else {
    //         // Check if same item - don't count
    //         if (this.voteModel.vote3 === this.pitch.$key) {
    //             // NO NO
    //         } else {
    //             curVotes++
    //         }
    //     }

    //     let preVotes = 3
    //     if (!preVote1) {
    //         preVotes--
    //     }
    //     if (!preVote2) {
    //         preVotes--
    //     }
    //     if (!preVote3) {
    //         preVotes--
    //     }

    //     // Check the limit
    //     // preVotes = this.voteModel.votes + preVotes;
    //     if (preVotes + curVotes > this.max) {
    //         this.votingLimit.emit();
    //         return
    //     }

    //     this.voteModel.votes = preVotes + curVotes

    //     // Reset
    //     this.vote1 = false
    //     this.vote2 = false
    //     this.vote3 = false

    //     for (let i = 0; i < id; i++) {
    //         if (i === 0) {
    //             this.vote1 = voted
    //         } else if (i === 1) {
    //             this.vote2 = voted
    //         } else {
    //             this.vote3 = voted
    //         }
    //     }

    //     this.robot1 = this.vote1 ? this.robotYes : this.robotNo;
    //     this.robot2 = this.vote2 ? this.robotYes : this.robotNo;
    //     this.robot3 = this.vote3 ? this.robotYes : this.robotNo;

    //     // let voteAdded = false;

    //     // curVotes = 0
    //     // if (this.vote1) {
    //     //     if (this.voteModel.vote1 === '') {
    //     //         voteAdded = true
    //     //         this.voteModel.vote1 = this.pitch.$key
    //     //     }
    //     // } else {
    //     //     if (this.voteModel.vote1 === '') {
    //     //         this.voteModel.vote1 = ''
    //     //     }
    //     // }
    //     // if (this.vote2) {
    //     //     this.voteModel.vote2 = this.pitch.$key
    //     // } else {
    //     //     this.voteModel.vote2 = ''
    //     // }
    //     // if (this.vote3) {
    //     //     this.voteModel.vote3 = this.pitch.$key
    //     // } else {
    //     //     this.voteModel.vote3 = ''
    //     // }
    //     this.emitEvent();
    // }

    emitEvent() {
        this.votingChanged.emit(this.voteContainer);
    }

    private updateDisplay(id: Number, voted: boolean) {
        // Reset
        this.vote1 = false
        this.vote2 = false
        this.vote3 = false

        for (let i = 0; i < id; i++) {
            if (i === 0) {
                this.vote1 = voted
            } else if (i === 1) {
                this.vote2 = voted
            } else {
                this.vote3 = voted
            }
        }

        this.robot1 = this.vote1 ? this.robotYes : this.robotNo;
        this.robot2 = this.vote2 ? this.robotYes : this.robotNo;
        this.robot3 = this.vote3 ? this.robotYes : this.robotNo;
    }
}
