export class Pitch {
  public $key: string;

  public author: string;
  public title: string;
  public description: string;
  public priority: number;
  public timestamp: object;

  public members: [any];
}
