import { Vote } from './vote.model';
import { Pitch } from './pitch.model';

export class VoteContainer {
  public $key: string;
  public email: string;
  public votes = [];

  public priority: number;
  public timestamp: object;
}
