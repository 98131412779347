import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { environment } from '../environments/environment';
import { SimpleNotificationsModule } from 'angular2-notifications';

import { HomeCountdownComponent } from './components/home-countdown/home-countdown.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PitchSubmitComponent } from './components/pitch-submit/pitch-submit.component';
import { VoteComponent } from './components/vote/vote.component';
import { LoadingModule } from './shared/componets/loading/loading.module';
import { PagerModule } from './shared/componets/pager/pager.module';
import { ResultsComponent } from './components/results/results.component';
import { VotingModule } from './shared/componets/voting/voting.module';
import { PitchesComponent } from './components/pitches/pitches.component';
import { DatabaseService } from './shared/services';

import { Routing } from './app.routing';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { FormsModule } from '@angular/forms';
import { ArchwizardModule } from 'angular-archwizard';
import { ProjectsComponent } from './components/projects/projects.component';

@NgModule({
  declarations: [
    AppComponent, HomeCountdownComponent, PageNotFoundComponent, PitchSubmitComponent,
    VoteComponent, PitchesComponent, ResultsComponent, ProjectsComponent
  ],
  imports: [
    BrowserModule, FormsModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, Routing,
    AngularFireDatabaseModule, AngularFontAwesomeModule,
    SimpleNotificationsModule.forRoot(),
    AngularFireStorageModule, NgbModule,
    LoadingModule, PagerModule, VotingModule,
    ArchwizardModule
  ],
  providers: [ DatabaseService ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
